const url_prefix = 'weather'
const url = 'https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/weatherUI/'
const weather_url = url + url_prefix
const unknown_weather = {
	name: '未知',
	code: 0,
}
const weatherTypes = [{
	name: '晴',
	code: 1,
}, {
	name: '多云',
	code: 2,
}, {
	name: '阴',
	code: 3,
}, {
	name: '小雨',
	code: 4,
}, {
	name: '小雨-中雨',
	code: 5,
}, {
	name: '中雨-大雨',
	code: 6,
}, {
	name: '阵雨',
	code: 7,
}, {
	name: '中雨',
	code: 8,
}, {
	name: '大雨',
	code: 9,
}, {
	name: '暴雨',
	code: 10,
}, {
	name: '大暴雨',
	code: 11,
}, {
	name: '特大暴雨',
	code: 12,
}, {
	name: '雷阵雨',
	code: 13,
}, {
	name: '阵雪',
	code: 14,
}, {
	name: '中雪',
	code: 15,
}, {
	name: '大雪',
	code: 16,
}, {
	name: '暴雪',
	code: 17,
}, {
	name: '小雪',
	code: 18,
}, {
	name: '小雪-中雪',
	code: 19,
}, {
	name: '雾',
	code: 20,
}, {
	name: '冻雨',
	code: 21,
}, {
	name: '沙尘暴',
	code: 22,
}, {
	name: '大雨-暴雨',
	code: 23,
}, {
	name: '中雪-大雪',
	code: 24,
}, {
	name: '大雪-暴雪',
	code: 25,
}, {
	name: '扬沙',
	code: 26,
}, {
	name: '浮尘',
	code: 27,
}, {
	name: '强沙尘暴',
	code: 28,
}, {
	name: '霾',
	code: 29,
}, {
	name: '雨夹雪',
	code: 30,
}]
export function getWeatherIcon(w = '') {
	if (!w) return weather_url + unknown_weather.code + '.png'
	const weatherObj = weatherTypes.find(item => {
		return w === item.name
	})
	if (weatherObj) {
		return weather_url + weatherObj.code + '.png'
	} else {
		return weather_url + unknown_weather.code + '.png'
	}

}