<template>
  <div class="hour">
    <div class="hour-head">
      <div class="hour-head-item">
        <div class="hour-head-refresh" @click="getList">
          <img src="../../../assets/images/realTimePositioning/map-sx.png" alt="" srcset="">
          <span>刷新</span>  
        </div>
        <div class="hour-head-record" style="margin-left: 8px;">
          <img src="../../../assets/images/realTimePositioning/hour-tj.png" alt="" srcset="">
          <div>总工时：{{ tatolTime }}</div>
        </div>
      </div>
      <div class="hour-head-item">
        <el-select v-model="bodyData.status" clearable placeholder="请选择" style="margin-right: 8px;">
          <el-option label="全部" :value="''">
          </el-option>
          <el-option label="开工" value="1">
          </el-option>
          <el-option label="停工" value="0">
          </el-option>
        </el-select>
        <el-date-picker
          v-model="bodyData.dateTime"
          type="daterange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right">
        </el-date-picker>
        <div class="hour-head-twins" style="margin-left: 8px;">
          <div class="hour-head-twins-identical hour-head-twins-left" :class="[ bodyData.type == 'list' ? 'hour-head-twins-check-' + bodyData.type : '' ]" @click="bodyData.type = 'list'">
            <img v-if="bodyData.type == 'list'" src="../../../assets/images/realTimePositioning/hour-lb-t.png" alt="" srcset="">
            <img v-else src="../../../assets/images/realTimePositioning/hour-lb.png" alt="" srcset="">列表
          </div>
          <div class="hour-head-twins-identical hour-head-twins-right" :class="[ bodyData.type == 'charts' ? 'hour-head-twins-check-' + bodyData.type : '' ]" @click="bodyData.type = 'charts'">
            <img v-if="bodyData.type == 'charts'" src="../../../assets/images/realTimePositioning/hour-tb-t.png" alt="" srcset="">
            <img v-else src="../../../assets/images/realTimePositioning/hour-tb.png" alt="" srcset="">图表
          </div>
        </div>
        <div class="hour-head-refresh" style="margin-left: 8px;" @click="hadnleExport" v-if="bodyData.type == 'list'">
          <img src="../../../assets/images/realTimePositioning/hour-dc.png" alt="" srcset="">
          <span>导出</span>  
        </div>
      </div>
    </div>
    <div class="hour-content" v-loading="isLoading">
      <SCTable v-show="bodyData.type == 'list'" ref="list" :tableData="tableData" :total="tableTotal" @changeConditions="onChangeConditions">
        <el-table-column
          type="selection"
          width="48">
        </el-table-column>
        <el-table-column
          prop="index"
          label="序号"
          width="70">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="uploadDay"
          label="日期">
        </el-table-column>
        <el-table-column
          prop="statusBeginShort"
          label="开始时间">
        </el-table-column>
        <el-table-column
          prop="statusEndShort"
          label="结束时间">
        </el-table-column>
        <el-table-column
          prop="statusStr"
          label="工作状态"
          width="180">
          <template slot-scope="scope">
            <div class="hour-content-status" :class="[ (scope.row.status == 1) ? 'hour-content-status2' : 'hour-content-status0']">{{ getStateLabel(scope.row.status) }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="durationStr"
          label="持续时间">
        </el-table-column>
        <!-- <el-table-column
          prop="date"
          label="平均速度(km/h)"
          width="180">
        </el-table-column>
        <el-table-column
          prop="date"
          label="里程(km)"
          width="180">
        </el-table-column> -->
      </SCTable>
      <SCCharts v-show="bodyData.type == 'charts'" ref="charts" :vehicleData="currentVehicle" :tableData="chartsData" :total="tableTotal" @changeConditions="onChangeConditions"></SCCharts>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import SCTable from "./table"
import SCCharts from "./SCCharts"
import { getWorkHourDetail, workingSum, exportWorkHourDetail } from "@/api/vehicleApi"
import { getDwellTime3 } from "@/utils/formatDate";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bodyData: {
        dateTime: [
          dayjs().add(-7, 'day').format('YYYY-MM-DD'),
           dayjs().format('YYYY-MM-DD')
        ],
        status: '',
        type: 'list'
      },
      pickerOptions: {
          shortcuts: [{
            text: '今天',
            onClick(picker) {
              const start = dayjs().startOf('day');
              const end = dayjs().endOf('day');
              picker.$emit('pick', [start, end]);
            }
          },{
            text: '本周',
            onClick(picker) {
              const start = dayjs().startOf('week').add(1, 'day');
              const end = dayjs().endOf('week').add(1, 'day');
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '本月',
            onClick(picker) {
              const start = dayjs().startOf('month');
              const end = dayjs().endOf('month');
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '全年',
            onClick(picker) {
              const start = dayjs().startOf('year');
              const end = dayjs().endOf('year');
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      tableData: [],
      tatolTime: '0时0分',
      conditionsData: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableTotal: 0,
      chartsData: {},
      isLoading: false,
    }
  },
  components: { SCTable, SCCharts },
  watch: {
    bodyData: {
      handler(v) {
        this.getList('reset', true);
      },
      deep: true,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type, flag) {
      try {
        if (type == 'reset') {
          this.$refs[this.bodyData.type].handleCurrentChange(1);
          if(flag) return false;
        }
        let api = this.bodyData.type == 'list' ? getWorkHourDetail : workingSum;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          status: this.bodyData.status,
          ...this.conditionsData
        };
        if (this.bodyData.dateTime && this.bodyData.dateTime.length) {
          data.beginDateTime = dayjs(this.bodyData.dateTime[0]).format('YYYY-MM-DD HH:mm:ss');
          data.endDateTime = dayjs(this.bodyData.dateTime[1]).format('YYYY-MM-DD 23:59:59');
        }
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.flag == 1 && this.bodyData.type == 'list') {
          this.tableData = res.obj.workHourDetails;
          let dateTime = getDwellTime3(((data.status !== '1' ? Number(res.obj.inIdlingSum) : 0) + (data.status !== '0' ? Number(res.obj.inWorkingSum) : 0)) * 1000);
          this.tatolTime = `${dateTime[0]}时${dateTime[1]}分`;
          this.tableTotal = res.obj.count || 0;
        }else if (res.flag == 1 && this.bodyData.type == 'charts') {
          this.chartsData = JSON.parse(JSON.stringify(res.obj.workingHoursList));
          let dateTime = getDwellTime3(((data.status !== '1' ? Number(res.obj.inIdlingSum) : 0) + (data.status !== '0' ? Number(res.obj.inWorkingSum) : 0)) * 1000);
          this.tatolTime = `${dateTime[0]}时${dateTime[1]}分`;
          this.tableTotal = res.obj.count || 0;
        }
      } catch (error) {
        
      }
    },
    onChangeConditions(e) {
      this.conditionsData = e;
      this.getList();
    },
    hadnleExport() {
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          status: this.bodyData.status,
          ...this.conditionsData
        };
        if (this.bodyData.dateTime && this.bodyData.dateTime.length) {
          data.beginDateTime = dayjs(this.bodyData.dateTime[0]).format('YYYY-MM-DD HH:mm:ss');
          data.endDateTime = dayjs(this.bodyData.dateTime[1]).format('YYYY-MM-DD 23:59:59');
        }
      exportWorkHourDetail(data);
    },
    getStateLabel(e) {
      // if (this.currentVehicle.isCommercialVehicles && e == 2) return '开工';
      // else if (this.currentVehicle.isCommercialVehicles && e == 4) return '停工';
      // else if (this.currentVehicle.isCommercialVehicles && e == 5) return '熄火';
      // if (e === 0) return '怠速';
      // if (e === 1) return '行驶';
      // if (e === 2) return '作业';
      // if (e === 3) return '通电';
      // if (e === 4) return '熄火';
      if (e === 0) return '停工';
      if (e === 1) return '开工';
      if (e === 5) return '离线';
      return "从未上线";
      
      // return '未知';
    },
  }
}
</script>

<style lang="scss" scoped>
.hour {
  padding: 16px 12px;
  background: #FFFFFF;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080FF;
      background: #E6F3FF;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins{
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 73px;
        height: 32px;
        img {
          width: 14px;
          height: 14px;
          margin-right: 6px;
        }
      }
      &-left {
        border: 1px solid #DEE3E6;
        border-radius: 16px 0 0 16px;
        border-right: none;
      }
      &-right {
        border: 1px solid #DEE3E6;
        border-left: none;
        border-radius: 0 16px 16px 0;
      }

      &-check-list {
        border-color: #0080FF;
        border-right: 1px solid #0080FF;
        color: #0080FF;
      }
      &-check-charts {
        border-color: #0080FF;
        border-left: 1px solid #0080FF;
        color: #0080FF;
      }
    }
  }
  &-content {
    height: calc(100% - 56px);
    margin-top: 24px;
      &-status {
        // width: 40px;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        text-align: center;
        padding: 0 10px;
        font-size: 12px;
        color: #999999;
        background: #EEEEEE;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #E1E1E1;
      }
      &-status0 {
        color: #FCA118;
        background: #FFF5E7;
        border: 1px solid #FFE4BD;
      }
      &-status2 {
        color: #00BC0D;
        background: #E5F8E6;
        border: 1px solid #B7EDBA;
      }
      &-status3 {
        color: #3470FF;
        background: #EAF0FF;
        border: 1px solid #C5D6FF;
      }
  }
}
</style>