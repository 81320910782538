<template>
  <div class="track">
    <div class="track-head">
      <div class="track-head-item">
        <div class="track-head-refresh" @click="getList()">
          <img
            src="../../../assets/images/realTimePositioning/map-sx.png"
            alt=""
            srcset=""
          />
          <span>刷新</span>
        </div>
      </div>
      <div class="track-head-item">
        <div class="track-head-twins" style="margin-left: 8px">
          <el-popover placement="bottom" width="150" trigger="click" v-if="currentVehicle.isCommercialVehicles">
            <div class="track-headc">
              <el-checkbox-group v-model="bodyData.alarmTypeList">
                <el-checkbox
                  v-for="(item, index) in alarmTypeCheckList"
                  :key="index"
                  :label="item.alarmName"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
            <div
              class="track-head-twins-botton2"
              slot="reference"
            >
              <img
                src="../../../assets/images/realTimePositioning/track-screen.png"
                alt=""
                srcset=""
              />
              筛选告警类型
            </div>
          </el-popover>
          <div
            class="track-head-twins-identical track-head-twins-right"
            style="margin-right: 24px"
          >
            
            <div class="el-date-b">
              <div class="track-head-twins-botton" @click="handleNextDate(-1)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <el-date-picker
                v-model="bodyData.dateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <!--  -->
              <div
                class="track-head-twins-botton"
                :class="{
                  'track-head-twins-botton-d': bodyData.dateTime == endDateTime,
                }"
                @click="handleNextDate(1)"
              >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <el-popover placement="bottom" width="150" trigger="click">
            <div class="track-headc">
              <el-radio-group v-model="speedValue">
                <el-radio :label="500">0.5X</el-radio>
                <el-radio :label="1000">1.0X</el-radio>
                <el-radio :label="2000">2.0X</el-radio>
                <el-radio :label="4000">4.0X</el-radio>
                <el-radio :label="16000">16.0X</el-radio>
              </el-radio-group>
            </div>
            <div class="track-head-twins-botton2" slot="reference">
              {{ (speedValue / 1000).toFixed(1) }}x
              <i class="el-icon-arrow-down" style="margin-left: 4px"></i>
            </div>
          </el-popover>
          <div
            class="track-head-twins-play-b"
            @click="isStart ? pauseMove() : playTrack()"
          >
            <img
              v-if="isStart"
              src="../../../assets/images/realTimePositioning/track-pause.png"
              alt=""
              srcset=""
            />
            <img
              v-else
              src="../../../assets/images/realTimePositioning/track-play.png"
              alt=""
              srcset=""
            />
            {{ isStart ? "暂停" : "播放" }}
          </div>
        </div>
      </div>
    </div>
    <div class="track-content">
      <div class="track-content-map" v-loading="isLoading">
        <AMap :center="mapCenter" :zoom="17">
          <!-- 展示地图信息窗体 -->
          <InfoWindow
            v-if="windowInfo.id"
            :lngLat="windowPoint"
            :offset="[-4, -2]"
            :width="236"
            :height="156"
            :showClose="true"
          >
            <div class="window-title">告警信息</div>
            <div class="window-info">
              <div class="window-info-label">告警类型：</div>
              <div class="window-info-vlue">{{ windowInfo.alarmName }}</div>
              <div class="window-info-status" :class="[ 'window-info-status' + windowInfo.level ]">{{ windowInfo.level == 1 ? '低' : windowInfo.level == 2 ? '中' : '高' }}</div>
            </div>
            <div class="window-info">
              <div class="window-info-label">车身速度：</div>
              <div class="window-info-vlue">{{ windowInfo.speed }}km/h</div>
            </div>
            <div class="window-info">
              <div class="window-info-label">告警时间：</div>
              <div class="window-info-vlue">{{ windowInfo.devTime }}</div>
            </div>
            <div class="window-button" @click="isVisible = true">点击查看详情</div>
          </InfoWindow>

          <!-- 轨迹回放 -->
          <MapTrack
            ref="mapTrackRef"
            :path="trackProps.path"
            :speed="speedValue" :autoRotation="true"
            :markerOpts="markerOpts"
            :markerOffset="[0, 0]"
            :startMarkerOpts="markerOpts"
            :endMarkerOpts="markerOpts"
            @moving="movingClick"
            @moveEnd="onMoveEnd" @onReplacement="onReplacement"
          >
            <template #moveMarker>
              <Iconfont
                name="https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/basic/common/ui/points-icon-go2.png"
                :size="48"
                :rotate="infoProps.params.direction || 0"
              ></Iconfont>
            </template>
            <template #startMarker>
              <Iconfont name="icon-qi" :size="32"></Iconfont>
            </template>
            <template #endMarker>
              <Iconfont name="icon-zhong" :size="32"></Iconfont>
            </template>
          </MapTrack>

          <!-- 聚合点 -->
          <MapClusterer
            ref="clusterObj"
            :points="clusterPoints"
            :minClusterSize="10"
            pointKey="key"
            :markerOffset="[0, 0]"
            @markerClick="onMarkerClick"
          >
            <template #marker="{ point }">
              <!-- <Iconfont
                :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
                :size="32"
                :rotate="point.data.F || 0"
              ></Iconfont>-->
              <!-- <Iconfont name="icon-a-zu517" :size="32" :rotate="point.data.F || 0"></Iconfont> -->
              <Iconfont
                :size="18"
                name="https://yili-platform-v2.oss-cn-shenzhen.aliyuncs.com/common/basic/common/ui/points-icon-alarm.png"
              ></Iconfont>
            </template>
          </MapClusterer>
        </AMap>
      </div>
      <div class="track-content-alarm">
        <div class="track-content-alarm-statistics" v-if="!currentVehicle.isCommercialVehicles">
          <div class="track-content-alarm-statistics-item">
            <img
              src="../../../assets/images/realTimePositioning/map-lc.png"
              class="tab-icon"
              alt=""
              srcset=""
            />
            今日里程：
            <span>{{ statisticsObj.mileageSum || '--' }}km</span>
          </div>
          <div class="track-content-alarm-statistics-item">
          <img
            src="../../../assets/images/realTimePositioning/map-sd.png"
            alt=""
            srcset=""
          />
            平均速度：
            <span>{{ statisticsObj.d || '--' }}km/h</span>
          </div>
        </div>
        <div class="track-content-alarm-y">
        <div
          class="track-content-alarm-item"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="track-content-alarm-item-status">
            <div
              :class="['track-content-alarm-item-status-' + item.stopStatus]"
            >
              {{ item.stopStatusStr }}
            </div>
            {{ item.devTime }}
          </div>
          <div class="track-content-alarm-item-dynamic">
            <div
              class="track-content-alarm-item-dynamic-item"
              v-if="item.stopStatus == 2"
            >
              <AddressText
                :autoScroll="false"
                :lng="item.lon"
                :lat="item.lat"
              />
            </div>
            <div
              class="track-content-alarm-item-dynamic-item"
              v-if="item.stopStatus == 1"
              :class="['track-content-alarm-item-dynamic-item2']"
            >
              <AddressText
                :autoScroll="false"
                :lng="item.beginLon"
                :lat="item.beginLat"
              />
            </div>
            <div
              class="track-content-alarm-item-dynamic-item"
              v-if="item.stopStatus == 1"
              :class="['track-content-alarm-item-dynamic-item3']"
            >
              <AddressText
                :autoScroll="false"
                :lng="item.endLon"
                :lat="item.endLat"
              />
            </div>
          </div>
          <div class="track-content-alarm-item-statistics">
            <div style="border-left: none">持续：{{ item.burnTime }}</div>
            <div v-if="item.stopStatus == 1 && !currentVehicle.isCommercialVehicles">车速：{{ item.gpsSpeed }}km/h</div>
            <div v-if="item.stopStatus == 1 && !currentVehicle.isCommercialVehicles">里程：{{ item.mileage }}km</div>
          </div>
        </div>

        </div>
        
        <div class="vacancy" v-if="!tableData || !tableData.length">
          <img class="vacancy-icon" src="../../../assets/images/realTimePositioning/zwsj.png" alt="" srcset="">
          <div class="vacancy-text">列表暂无数据</div>
        </div>
      </div>
    </div>
    <alarmDetailsDialog v-model="isVisible"
      :editDetail="windowInfo" />
  </div>
</template>

<script>
import AMap, { MapTrack, InfoWindow, MapClusterer } from "@/components/AMap";
import dayjs from "dayjs";
import GPS from "@/utils/address";
import { getAddresses } from "@/components/AMap/utils";
import {
  getPCZKTrackAlarm,
  getAlarmConf,
  getPCZKAlarmDetail,
} from "@/api/vehicleApi";
import { getTrackInfo } from "@/api/getManagementData.js";
import AddressText from "@/components/AddressText";
import alarmDetailsDialog from "./alarmDetailsDialog";
export default {
  components: {
    MapTrack,
    AMap,
    InfoWindow,
    MapClusterer,
    AddressText,
    alarmDetailsDialog,
  },
  computed: {
    markerOpts() {
      return {
        offset: [-2, 8],
      };
    },
    // 聚合点
    clusterPoints() {
      const { alarmMarkerLst } = this;
      return alarmMarkerLst.map((obj, indd) => {
        const point = GPS.delta(obj.d[1], obj.d[0]);
        return {
          key: obj.id || indd,
          lng: point.lon,
          lat: point.lat,
          data: obj,
        };
      });
    },
  },
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  watch: {
    bodyData: {
      handler(v) {
        this.getList();
      },
      deep: true,
      immediate: false,
    },
    currentVehicle: {
      handler(v, old) {
        if (
          (old && 
          old.isCommercialVehicles !== undefined &&
          old.isCommercialVehicles === v.isCommercialVehicles) || v.isCommercialVehicles === undefined
        ){
          if (old.isCommercialVehicles === v.isCommercialVehicles) {
            this.getList();
          }
          return false;
        }
        this.init();
        // this.getList(true);
      },
      immediate: true,
    },
  },
  data() {
    return {
      mapCenter: [116.482169, 39.998932],
      windowPoint: [],
      // 播放速度
      speedValue: 1000,
      trackProps: {
        // 回放参数
        path: [],
      },
      dataList: [], // 记录初始数据
      tableData: [], // table 数据
      height: 0, // 虚拟table高度 必须要
      rowHeight: 55, // 默认行高
      infoProps: {
        // 窗体参数
        time: "",
        visible: false,
        lngLat: [114.340716, 23.012966],
        params: {},
        lon: "",
        lat: "",
        address: "",
      },
      windowInfo: {},
      bodyData: {
        dateTime: dayjs().format("YYYY-MM-DD"),
        speedValue: 1,
        alarmTypeList: [], //
      },
      alarmTypeCheckList: [], //
      endDateTime: dayjs().format("YYYY-MM-DD"),
      isStart: false,
      currentMarker: null,
      alarmMarkerLst: [],
      isVisible: false,
      isLoading: false,
      isStop: false,
      statisticsObj: {},
    };
  },
  created() {
    // this.$nextTick(()=>{
    //   this.init();
    // })
    // if (this.$route.query.tabType && this.$route.query.tabType == 'trackDetails') {
      
    // }
  },
  methods: {
    init() {
      getAlarmConf({ manufactorType: this.currentVehicle.manufactorType })
        .then((res) => {
          this.alarmTypeCheckList = res.obj || [];
          this.bodyData.alarmTypeList = (res.obj || []).map(
            (val) => val.alarmName
          );
        })
        .catch((err) => {
          this.alarmTypeCheckList = [];
          this.bodyData.alarmTypeList = [];
        });
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async setAddresses(points, type) {
      const result = await getAddresses(points);
      result.forEach(({ tag, address }) => {
        this.dataList[tag][["address", "address1"][type]] = address;
      });
    },
    // 播放轨迹点击事件
    async playTrack() {
      const { mapTrackRef } = this.$refs;
      if (!this.isStart && this.isStop) {
        mapTrackRef.resumeMove();
        this.isStart = true;
        this.isStop = false;
        return false;
      }
      if (!this.isStart || this.percentage === 100) {
        await mapTrackRef.resetMove();

        mapTrackRef.startMove();
      }

      this.isStart = true;

      if (this.isStart) mapTrackRef.resumeMove();
    },

    // 暂停播放
    pauseMove() {
      this.isStart = false;
      this.isStop = true;
      const { mapTrackRef } = this.$refs;
      mapTrackRef.pauseMove();
    },

    /**
     * 计算播放进度
     * moveToIndex: 下一个位置下标
     */
    movingClick(passedPath, moveToIndex, e) {
      const { tableData } = this;

      if (moveToIndex < tableData.length) {
        // this.windowPoint = passedPath[passedPath.length - 1];
        this.setMoveMarker(moveToIndex - 1);
      }
    },
    setMoveMarker(index) {
      const { tableData } = this;
      if (index < tableData.length) {
        // 移动的位置点信息
        const pointObj = tableData[index];
        this.infoProps.params = pointObj;
        this.infoProps.lngLat = [pointObj.lon, pointObj.lat];
        this.infoProps.address = pointObj.address;
        this.infoProps.visible = true;

        const percentage =
          Math.floor(((index + 1) / tableData.length) * 10000) / 100;
        this.percentage = percentage;

        this.scrollIntoView(pointObj, index);
      }
    },
    /**
     * table 设置当前行, 并滚动到可视区
     */
    async scrollIntoView(row, index) {
      const { rowHeight } = this;
      const { uTableObj } = this.$refs;
      if (!uTableObj) return;

      // 距离顶部高度
      const top = Math.max(0, (index + 0) * rowHeight);

      uTableObj.pagingScrollTopLeft(top, 0);
      await this.$nextTick();
      uTableObj.setCurrentRow(row);
    },
    onMoveEnd() {
      const { tableData, trackProps } = this;
      // TODO: 实现播放结束

      this.isStart = false;

      // this.windowPoint = trackProps[trackProps.path.length - 1];
      tableData.length ? this.setMoveMarker(tableData.length - 1) : '';
    },
    onReplacement() {
      this.isStart = false;
    },
    async getList(type) {
       if (type == 'reset') {
          return false;
      }
      const { mapTrackRef } = this.$refs;
      mapTrackRef?.replacementMove();
        let alarmTypeList =
          this.bodyData.alarmTypeList && this.bodyData.alarmTypeList.length
            ? this.alarmTypeCheckList
                .filter((val) =>
                  this.bodyData.alarmTypeList.includes(val.alarmName)
                )
                .map((val) => val.alarmId)
                .toString()
            : [];
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          plate: this.currentVehicle.P,
          beginTime: dayjs(this.bodyData.dateTime).format(
            "YYYY-MM-DD 00:00:00"
          ),
          endTime: dayjs(this.bodyData.dateTime).format("YYYY-MM-DD 23:59:59"),
          filterTime: 0,
          posStatus: 1,
          stopStatus: 1,
          stopFlag: 0,
          alarmTypeList,
        };
        if (!data.vehicleId) {
          return false;
        }
      if (this.currentVehicle.isCommercialVehicles) {
        getPCZKTrackAlarm(data).then((res) => {
          this.alarmMarkerLst = res.obj;
        }).catch((err) => {
          this.alarmMarkerLst = [];
        });
      }else  {
        this.alarmMarkerLst = [];
      }
      this.isLoading = true;
      const result = await getTrackInfo(data);
      this.isLoading = false;
      this.activeName = "1";

      if (!(result.flag && result.rows)) {
        this.tableData = [];
        this.dataList = []; // 记录查询的初始数据
        this.trackProps.path = []
        return this.$message.warning(result.msg);
      }

      if (!result.rows.length) {
        this.tableData = [];
        this.dataList = []; // 记录查询的初始数据
        this.trackProps.path = []
        return this.$message.warning("暂无数据");
      }

      const { extend } = result;
      this.statisticsObj = extend;
      this.tableData = result.rows.map((item) => ({
        ...item,
        plate: extend.plate,
        groupName: extend.groupName,
        vehicleShape: extend.vehicleShape,
        terminalNo: extend.terminalNo,
        terminalType: extend.terminalType,
        address: "",
      }));

      // 经纬度转换地址
      const points = result.rows
        .map((item, index) => ({
          lon: item.lon,
          lat: item.lat,
          tag: index,
        }))
        .filter((item) => item.lon != 0);

      this.setAddresses(points, 0);

      this.dataList = this.tableData; // 记录查询的初始数据
      let path = result.extend.lonLat.filter((val) => val[0] != 0 && val[1] != 0);
      this.trackProps.path = path.map((item) => {
        const point = GPS.delta(item[1], item[0]);
        return {
          lng: point.lon,
          lat: point.lat,
        };
      });
    },
    async onMarkerClick(e) {
      const point = GPS.delta(e.data.d[1], e.data.d[0]);
      this.windowPoint = [point.lon, point.lat];
      let res = await getPCZKAlarmDetail({
        alarmId: e.data.id,
        day: this.bodyData.dateTime,
        flag: this.currentVehicle.isCommercialVehicles ? 1 : ''
      });
      this.windowInfo = { ...(res.obj || {}), ...e.data };
    },
  },
};
</script>

<style lang="scss" scoped>
.track {
  height: 100%;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 12px;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;

    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080ff;
      background: #e6f3ff;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 50px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      &-botton2 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        padding: 0 8px;
        cursor: pointer;
        font-size: 12px;
        color: #333333;
        margin-right: 16px;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
        img {
          width: 16px;
          height: 16px;
          margin-right: 4px;
          margin-top: -2px;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      &-play-b {
        display: flex;
        align-items: center;
        width: 74px;
        height: 32px;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
        margin-right: 16px;
        background: #0080ff;
        border-radius: 16px;
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
          margin-left: 4px;
        }
      }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-headc {
    min-width: 126px;
    max-height: 240px;
    overflow-y: auto;
    ::v-deep .el-checkbox {
      display: block;
      margin: 0;
      margin-bottom: 14px;
    }
    ::v-deep .el-radio {
      display: block;
      margin: 0;
      margin-bottom: 14px;
    }
  }
  &-content {
    height: calc(100% - 64px);
    display: flex;
    &-alarm {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 383px;
      height: 100%;
      padding: 16px;
      padding-top: 0;
      background: #ffffff;
      &-y {
        // height: calc(100% - 42px);
        // height: 100%;
        flex: 1;
        padding-top: 16px;
        overflow-y: auto;
      }
      &-item {
        padding: 0 12px;
        margin-bottom: 10px;
        background: #ffffff;
        box-shadow: 0px 0px 6px 1px rgba(0, 128, 255, 0.1);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-status {
          display: flex;
          align-items: center;
          margin-top: 15px;
          font-weight: 600;
          font-size: 16px;
          color: #333333;
          &-2 {
            width: 46px;
            height: 21px;
            line-height: 21px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            margin-right: 10px;
            background: #999999;
            border-radius: 20px;
          }
          &-1 {
            width: 46px;
            height: 21px;
            line-height: 21px;
            text-align: center;
            font-size: 12px;
            color: #ffffff;
            margin-right: 10px;
            background: #2ccc14;
            border-radius: 20px;
          }
        }
        &-dynamic {
          padding: 10px 0;
          border-bottom: 1px solid #dee3e6;
          &-item {
            position: relative;
            font-size: 14px;
            color: #666666;
            padding-left: 20px;
          }
          &-item::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transform: translateY(50%);
            width: 8px;
            height: 8px;
            background: #409eff;
            border-radius: 50%;
          }
          &-item2::before {
            background: #67c23a;
            z-index: 9;
          }
          &-item3 {
            margin-top: 10px;
          }
          &-item3::before {
            background: #f56c6c;
            z-index: 9;
          }
          &-item3::after {
            content: "";
            position: absolute;
            height: calc(100% + 10px);
            left: 3px;
            bottom: 0;
            transform: translateY(-50%);
            border-left: 2px dashed #eaedf3;
          }
        }
        &-statistics {
          width: 100%;
          display: flex;
          align-items: center;
          height: 40px;
          font-size: 12px;
          color: #666666;
          div {
            width: 33.3%;
            display: flex;
            align-items: center;
            justify-content: left;
            padding-left: 8px;
            border-left: 1px solid #dee3e6;
          }
        }
      }
      &-statistics {
        display: flex;
        align-items: center;
        height: 42px;
        padding: 0 16px;
        background: #EFF7FF;
        &-item {
          flex: 1;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #333333;
          img {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
          span {
            font-size: 14px;
            color: #0080FF;
          }
        }
      }
      .vacancy {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #fff;
        &-icon {
          width: 121px;
          height: 94px;
        }
        &-text {
          font-size: 15px;
          color: #333333;
          margin-top: 5px;
        }
      }
    }
    &-map {
      flex: 1;
      height: 100%;
      ::v-deep .WindowContent {
        border: none;
        padding: 12px 10px;
        background: #ffffff;
        box-shadow: 0px 0px 20px 1px rgba(51, 51, 51, 0.12);
        border-radius: 10px 10px 10px 10px;
      }
      .window {
        &-title {
          font-size: 14px;
          color: #333333;
          margin-bottom: 8px;
        }
        &-info {
          position: relative;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          &-label {
            font-size: 14px;
            color: #999999;
          }
          &-value {
            font-size: 14px;
            color: #333333;
          }
          &-status {
            position: absolute;
            top: 0;
            right: 0;
            width: 43px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            font-size: 12px;
            color: #FFFFFF;
            border-radius: 20px;
          }
          &-status1 {
            background: #409EFF;
          }
          &-status2 {
            background: #FDA835;
          }
          &-status3 {
            background: #F64A2D;
          }
        }
        &-button {
          cursor: pointer;
          text-align: center;
          font-size: 14px;
          color: #377dff;
        }
      }
    }
  }
}
</style>