<template>
  <div class="table-box">
    <el-table :data="tableData" stripe height="calc(100% - 40px)" style="width: 100%">
      <slot></slot>
      <template slot="empty">
        <div class="vacancy">
          <img
            class="vacancy-icon"
            src="../../../assets/images/realTimePositioning/zwsj.png"
            alt=""
            srcset=""
          />
          <div class="vacancy-text">列表暂无数据</div>
        </div>
      </template>
    </el-table>
    <el-pagination style="margin-top: 8px" v-if="isPagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableConditions.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="tableConditions.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: [Number,String],
      default: 0,
    },
    isPagination: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      tableConditions: {
        pageIndex: 1,
        pageSize: 10
      }
    }
  },
  methods: {
    handleSizeChange(e) {
      this.tableConditions.pageSize = e;
      if (this.tableConditions.pageIndex * this.tableConditions.pageSize > this.total) {
        this.tableConditions.pageIndex = Math.ceil(this.total / e);
      };
      this.$emit('changeConditions', this.tableConditions);
    },
    handleCurrentChange(e) {
      this.tableConditions.pageIndex = e;
      this.$emit('changeConditions', this.tableConditions);
    },

  }
};
</script>

<style lang="scss" scoped>
.table-box {
  height: 100%;
  ::v-deep .el-table {
    .el-table__header-wrapper {
      background-color: #f2f7fd;
      border-bottom: 1px solid #dee3e6;
      .el-table__cell {
        font-weight: bold;
        font-size: 14px;
        color: #333333;
        border-bottom: none;
        background-color: transparent;
        .cell {
          position: relative;
        }
        .cell::before {
          content: "";
          position: absolute;
          width: 1px;
          background-color: #dee3e6;
          top: 0;
          right: 0;
          bottom: 0;
        }
      }
      tr {
        background-color: transparent;
      }
    }
    .el-table__row {
      .el-table__cell {
        font-size: 14px;
        color: #333333;
      }
    }
  }
}
.vacancy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 100px);
  background-color: #fff;
  &-icon {
    width: 121px;
    height: 94px;
  }
  &-text {
    font-size: 15px;
    color: #333333;
    margin-top: 5px;
  }
}
</style>