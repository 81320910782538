<template>
  <div class="hour">
    <div class="hour-content" v-loading="isLoading">
      <SCTable ref="SCTable" :tableData="tableData" :total="tableTotal" @changeConditions="onChangeConditions">
        <el-table-column
          prop="index"
          label="序号"
          width="70">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          prop="insuranceOfDate"
          label="投保日期" width="180">
          <template slot-scope="scope">
            {{ scope.row.insuranceOfDate || '--' }}
          </template>
        </el-table-column>
        <el-table-column
          prop="level"
          label="保单号" width="180">
          <template slot-scope="scope">
            <div class="el-button el-button--text el-button--small"
              style="text-decoration: underline; padding: 0"
              @click="handleAlarmDetails(scope.row)">
              {{ scope.row.policyNo || '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="timeBegin"
          label="保障期限" width="206">
          <template slot-scope="scope">
            {{ scope.row.startDate || '--' }} 至 {{ scope.row.endDate || '--' }}
          </template>
        
        </el-table-column>
        <el-table-column
          prop="statusName"
          label="保单状态" width="180">
          <template slot-scope="scope">
            <div class="hour-content-status" :class="[ 'hour-content-status' + (scope.row.statusName == '保障中' ? '1' : scope.row.statusName == '未生效' ? '2' : '') ]">{{ scope.row.statusName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="totalPremium"
          label="总保费(元)" width="180">
          <template slot-scope="scope">
            {{ scope.row.totalPremium || '--' }}
          </template>
        
        </el-table-column>
        <el-table-column
          prop="policyHolder"
          label="投保人" width="180">
          <template slot-scope="scope">
            {{ scope.row.policyHolder || '--' }}
          </template>
        
        </el-table-column>
        <el-table-column
          prop="insured"
          label="被保险人" width="180">
          <template slot-scope="scope">
            {{ scope.row.insured || '--' }}
          </template>
        
        </el-table-column>
        <el-table-column
          prop="insuranceCompanyName"
          label="保险公司" width="180">
          <template slot-scope="scope">
            {{ scope.row.insuranceCompanyName || '--' }}
          </template>
        
        </el-table-column>
        <el-table-column
          prop="insuranceBranchName"
          label="分支机构" width="180">
          <template slot-scope="scope">
            {{ scope.row.insuranceBranchName || '--' }}
          </template>
        
        </el-table-column>
        <!-- <el-table-column
          prop="alarmAddress"
          label="告警位置"
          >
          <template slot-scope="scope">
            <AddressText
                :autoScroll="false"
                :lng="scope.row.lon"
                :lat="scope.row.lat"
              />
          </template>
        </el-table-column> -->
        <el-table-column
          prop="salesmanName"
          label="业务员">
          <template slot-scope="scope">
            {{ scope.row.salesmanName || '--' }}
          </template>
        
        </el-table-column>
        <el-table-column
          prop="date"
          label="操作"
          width="180">
          <template slot-scope="scope">
            <el-button type="text" @click="handleAlarmDetails(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </SCTable>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import SCTable from "./table";
import { getDevicePolicy } from "@/api/vehicleApi";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { appConfig } from '@/config/appConfig';
import AddressText from "@/components/AddressText";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bodyData: {
        dateTime: dayjs().format('YYYY-MM-DD'),
        level: '',
        alarmTypeIdList: '',
      },
      levelOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '低',
          value: '1',
        },
        {
          label: '中',
          value: '2',
        },
        {
          label: '高',
          value: '3',
        }
      ],
      typeOptions: [],
      endDateTime: dayjs().format('YYYY-MM-DD'),
      tableData: [],
      tatolTime: '0时0分',
      conditionsData: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableTotal: 0,
      chartsData: {},
      editData: {},
      isVisible: false,
      isImport: false,
      visibleDialog: false,
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      isLoading: false,
    }
  },
  components: { SCTable, AddressText },
  watch: {
    bodyData: {
      handler(v) {
        this.getList('reset');
      },
      deep: true,
    },
    currentVehicle: {
      handler(v, old) {
      },
      immediate: true,
      deep: true,
    }
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      try {
        if (type == 'reset') {
          this.$refs.SCTable.handleCurrentChange(1);
          return false;
        }
        let api = getDevicePolicy;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          frameNo: this.currentVehicle.frameNo,
          index: this.conditionsData.pageIndex,
          size: this.conditionsData.pageSize,
        };
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.flag == 1) {
          this.tableData = res.obj.policyDetailList || [];
          this.tableTotal = res.obj.count || 0;
        }
      } catch (error) {
        
      }
    },
    onChangeConditions(e) {
      this.conditionsData = e;
      this.getList();
    },
    hadnleExport() {
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          ...this.conditionsData,
          ...this.bodyData
        };
        if (this.bodyData.dateTime) {
          data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format('YYYY-MM-DD 00:00:00');
          data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format('YYYY-MM-DD 23:59:59');
        }
      exportSCAlramList(data);
    },
    getStateLabel(e) {
      if (e === 1) return '低';
      if (e === 2) return '中';
      if (e === 3) return '高';
      return '未知';
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime).add(e, 'day').format('YYYY-MM-DD')
    },
    async handleAlarmDetails(item) {
        this.$router.push({
          path: `/routerPolicyManagement/${ item.category == 1 ? 'ConstructionMachinery' : item.category == 2 ? 'CarInsurance' : 'Insurance' }/policyDetails/` + item.id,
        });
    },
    
    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmNameEnglish: row.alarmNameEnglish, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: { 1: '车载终端', 2: '企业监控平台', 3: '政府监管平台', 9: '其他' }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId
      };
    },
    handleDownloadSttachment(item) {
      // safetyManageDam({alarmId: item.alarmId})
      window.open(appConfig.base2GUrl2 + `/safetyManage/dam?alarmId=${item.alarmId}&token=${this.$store.state.appToken}&platform=PC`, '_blank')
    },
    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    }, 
    // 指令下发后刷新数据
    onCmdSendChange() {
      this.isImport = false;
      this.getList();
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  }
}
</script>

<style lang="scss" scoped>
.hour {
  padding: 16px 12px;
  background: #FFFFFF;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080FF;
      background: #E6F3FF;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins{
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 50px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #F2F7FD;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #DEE3E6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      // &-check-list {
      //   border-color: #0080FF;
      //   border-right: 1px solid #0080FF;
      //   color: #0080FF;
      // }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-content {
    height: calc(100% - 56px);
    margin-top: 24px;
      &-status {
        width: 66px;
        height: 30px;
        background: rgba(204,204,204,0.1);
        border-radius: 2px 2px 2px 2px;
        border: 1px solid rgba(204,204,204,0.2);
        line-height: 30px;
        text-align: center;
        font-size: 14px;
        color: #CCCCCC;
        border-radius: 2px;
      }
      &-status1 {
        font-size: 14px;
        color: #3470FF;
        background: rgba(52,112,255,0.1);
        border-radius: 2px 2px 2px 2px;
        border: 1px solid rgba(52,112,255,0.2);
      }
      &-status2 {
        font-size: 14px;
        color: #FCA118;
        background: rgba(252,161,24,0.1);
        border-radius: 2px 2px 2px 2px;
        border: 1px solid rgba(252,161,24,0.2);
      }
      &-status3 {
        background: #F64A2D;
      }
  }
}
</style>