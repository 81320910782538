<template>
  <div class="hour">
    <div class="hour-head">
      <div class="hour-head-item">
        <div class="hour-head-refresh" @click="getList">
          <img
            src="../../../assets/images/realTimePositioning/map-sx.png"
            alt=""
            srcset=""
          />
          <span>刷新</span>
        </div>
        <el-button type="success" v-if="isAdd" style="margin-left: 8px" icon="el-icon-circle-plus-outline" @click="editData = {vehicleId: currentVehicle.vehicleId, createUser: $store.state.userInfo.userId}, isVisible = true">添加</el-button>
      </div>
      <div class="hour-head-item">
        <div class="hour-head-twins" style="margin-left: 8px" v-if="isConditions">
          <div
            class="hour-head-twins-identical hour-head-twins-right"
            style="margin-right: 24px"
          >
            跟车日期
            <div class="el-date-b">
              <div class="hour-head-twins-botton" @click="handleNextDate(-1)">
                <i class="el-icon-arrow-left"></i>
              </div>
              <el-date-picker
                v-model="bodyData.dateTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <!--  -->
              <div
                class="hour-head-twins-botton"
                :class="{
                  'hour-head-twins-botton-d': bodyData.dateTime == endDateTime,
                }"
                @click="handleNextDate(1)"
              >
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div
          class="hour-head-refresh"
          style="margin-left: 8px"
          @click="hadnleExport"
        >
          <img
            src="../../../assets/images/realTimePositioning/hour-dc.png"
            alt=""
            srcset=""
          />
          <span>导出</span>
        </div>
      </div>
    </div>
    <div class="hour-content" v-loading="isLoading">
      <SCTable
        ref="SCTable"
        :tableData="tableData"
        :total="tableTotal"
        @changeConditions="onChangeConditions"
      >
        <el-table-column type="selection" width="48"> </el-table-column>
        <el-table-column prop="index" label="序号" width="70">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column prop="vehicleCondition" label="车辆状况" width="180">
          <template slot-scope="scope">
            <div :class="['status', statusClass[scope.row.vehicleCondition]]">
              {{ vehicleStatusList[scope.row.vehicleCondition] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="drivingHabits" label="驾驶行为习惯" width="180">
          <template slot-scope="scope">
            <div :class="['status', statusClass[scope.row.drivingHabits]]">
              {{ drivingHabitsList[scope.row.drivingHabits] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="patcherDate" label="跟车时间" width="180">
        </el-table-column>
        <el-table-column prop="patcher" label="跟车人">
          <!-- <template slot-scope="scope">
            <AddressText
                :autoScroll="false" v-if="scope.row.lon && scope.row.lat"
                :lng="scope.row.lon"
                :lat="scope.row.lat"
              />
              <span v-else>--</span>
          </template> -->
        </el-table-column>
        <el-table-column prop="remark" label="备注">
          <!-- <template slot-scope="scope">
            {{ scope.row.speed }}km/h
          </template> -->
        </el-table-column>
        <el-table-column prop="patcherReport" label="跟车报告">
          <template slot-scope="scope">
            <el-button v-if="scope.row.patcherReport == '已上传'" type="text">已上传</el-button>
            <span v-else>未上传</span>
          </template>
        </el-table-column>
        <el-table-column prop="createUserName" label="创建人">
          <!-- <template slot-scope="scope">
            {{ scope.row.speed }}km/h
          </template> -->
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间">
          <!-- <template slot-scope="scope">
            {{ scope.row.speed }}km/h
          </template> -->
        </el-table-column>
        <el-table-column prop="date" label="操作" width="120">
          <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.patcherReport == '已上传'" @click="handleDownUrl(scope.row)"
              >跟车报告</el-button
            >
            <el-button type="text" v-if="isAdd" @click="editData = scope.row, isVisible = true"
              >编辑</el-button
            >
            <el-button type="text" v-if="isAdd" @click="handleDel(scope.row)" style="color: #F64A2D;"
              >删除</el-button
            >            
          </template>
        </el-table-column>
      </SCTable>
    </div>
    <addFollowingDialog v-model="isVisible" :editDetail="editData" @changeList="getList" />

    <details-dialog
      v-model="isImport"
      :details="editData"
      @onHandleClick="onHandleClick(editData)"
      @downloadClick="handleDownloadSttachment(editData)"
    />

    <Dialog
      @on-cmdSend="onCmdSendChange"
      :visible="visibleDialog"
      :iProp="alarmiProp"
      @input="closeDialog"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import SCTable from "./table";
import addFollowingDialog from "@/views/riskControlManagement/components/addFollowingDialog";
import DetailsDialog from "@/views/SecurityMonitoring/StatisticalReport/components/DetailsDialog.vue";
import Dialog from "@/views/SecurityMonitoring/StatisticalReport/components/DealWithDialog.vue";
import {
  // carFollowingExport,
  exportSCAlramList,
  getAlarmConf,
  getPCZKAlarmDetail,
} from "@/api/vehicleApi";
import { followPage, carFollowingExport, followDelete } from "@/api/riskData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { appConfig } from "@/config/appConfig";
import AddressText from "@/components/AddressText";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
    isConditions: {
      type: Boolean,
      default: true,
    },
    isAdd: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      bodyData: {
        dateTime: '',
      },
      levelOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "低",
          value: "1",
        },
        {
          label: "中",
          value: "2",
        },
        {
          label: "高",
          value: "3",
        },
      ],
      typeOptions: [],
      endDateTime: dayjs().format("YYYY-MM-DD"),
      tableData: [],
      tatolTime: "0时0分",
      conditionsData: {
        index: 1,
        size: 10,
      },
      tableTotal: 0,
      chartsData: {},
      editData: {},
      isVisible: false,
      isImport: false,
      visibleDialog: false,
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      isLoading: false,
      statusClass: {
        1: "status1",
        2: "status2",
        3: "status3",
      },
      drivingHabitsList: {
        1: "良好",
        2: "一般",
        3: "差",
      },
      vehicleStatusList: {
        1: "良好",
        2: "一般",
        3: "维修",
      }
    };
  },
  components: {
    SCTable,
    addFollowingDialog,
    DetailsDialog,
    AddressText,
    Dialog,
  },
  watch: {
    bodyData: {
      handler(v) {
        this.getList("reset");
      },
      deep: true,
    },
    currentVehicle: {
      handler(v, old) {
        this.getList();
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    // this.getList();
  },
  methods: {
    async getList(type) {
      try {
        if (type == "reset") {
          this.$refs.SCTable.handleCurrentChange(1);
          return false;
        }
        if (!this.currentVehicle || !this.currentVehicle.vehicleId) {
          return false;
        }
        let api = followPage;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          ...this.conditionsData,
          ...this.bodyData,
        };
        // if (this.bodyData.dateTime) {
        //   data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format(
        //     "YYYY-MM-DD 00:00:00"
        //   );
        //   data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format(
        //     "YYYY-MM-DD 23:59:59"
        //   );
        // }
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.code == 200) {
          this.tableData = res.data.records;
          this.tableTotal = res.data.total || 0;
        }
      } catch (error) {}
    },
    onChangeConditions(e) {
      this.conditionsData = {
        index: e.pageIndex,
        size: e.pageSize,
      };
      this.getList();
    },
    hadnleExport() {
      let data = {
        vehicleId: this.currentVehicle.vehicleId,
        ...this.conditionsData,
        ...this.bodyData,
      };
      // if (this.bodyData.dateTime) {
      //   data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format(
      //     "YYYY-MM-DD 00:00:00"
      //   );
      //   data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format(
      //     "YYYY-MM-DD 23:59:59"
      //   );
      // }
      carFollowingExport(data);
    },
    getStateLabel(e) {
      if (e === 1) return "低";
      if (e === 2) return "中";
      if (e === 3) return "高";
      return "未知";
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async handleDownUrl(e) {
      var downloadLink = document.createElement("a");
      downloadLink.href = e.patcherReportUrl;
      downloadLink.download = `${this.currentVehicle.plate}-${e.patcher}在${e.patcherDate}跟车`; // 设置下载文件的名称
      downloadLink.target = "_blank";
      // 将下载链接添加到页面并模拟点击下载
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // 清理下载链接
      document.body.removeChild(downloadLink);
    },

    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmNameEnglish: row.alarmNameEnglish, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: {
          1: "车载终端",
          2: "企业监控平台",
          3: "政府监管平台",
          9: "其他",
        }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId,
      };
    },
    handleDownloadSttachment(item) {
      // safetyManageDam({alarmId: item.alarmId})
      window.open(
        appConfig.base2GUrl2 +
          `/safetyManage/dam?alarmId=${item.alarmId}&token=${this.$store.state.appToken}&platform=PC`,
        "_blank"
      );
    },
    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    },
    // 指令下发后刷新数据
    onCmdSendChange() {
      this.isImport = false;
      this.getList();
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
    handleDel(e) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
            let res = await followDelete({
              id: e.id,
            });
            if (res.code == 200) {
              this.getList();
            }
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    }
  },
};
</script>

<style lang="scss" scoped>
.hour {
  padding: 16px 12px;
  background: #ffffff;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080ff;
      background: #e6f3ff;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 50px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      // &-check-list {
      //   border-color: #0080FF;
      //   border-right: 1px solid #0080FF;
      //   color: #0080FF;
      // }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-content {
    height: calc(100% - 56px);
    margin-top: 24px;
    &-status {
      width: 43px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      border-radius: 20px;
    }
    &-status1 {
      background: #409eff;
    }
    &-status2 {
      background: #fda835;
    }
    &-status3 {
      background: #f64a2d;
    }
  }
  .status {
    position: relative;
    padding-left: 18px;
    font-size: 14px;
    display: inline-block;
  }
  .status::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  .status1 {
    color: #00bc0d;
  }
  .status1::after {
    background-color: #00bc0d;
  }
  // .status2 {
  //   color: #0080ff;
  // }
  // .status2::after {
  //   background-color: #0080ff;
  // }
  .status2 {
    color: #fca118;
  }
  .status2::after {
    background-color: #fca118;
  }
  .status3 {
    color: #F64A2D;
  }
  .status3::after {
    background-color: #F64A2D;
  }
}
</style>