<template>
  <div class="chart-box">
    <div  style="
              display: flex;
              justify-content: center;
              margin-bottom: 26px;
              position: sticky;
              top: 0;
            "
          >
            <div
              style="display: flex; margin-left: 32px"
              v-for="(item, index) in statuTypes" :key="index"
            >
              <div
                @click="ganttChange(index)"
                :style="{
                  width: '14px',
                  height: '14px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  backgroundColor: item.showBar ? item.color : '#cccccc',
                }"
              ></div>
              <span style="margin-left: 5px">{{ item.title }}</span>
            </div>
          </div>
    <div id="gantt" ref="gantt" class="gantt"></div>
    <el-pagination style="margin-top: 8px"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="tableConditions.pageIndex"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="tableConditions.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import * as d3 from "d3";
import dayjs from 'dayjs';
export default {
  components: { },
  props: {
    tableData: {
      type: Object,
      default: () => {},
    },
    vehicleData: {
      type: Object,
      default: () => {},
    },
    total: {
      type: [Number,String],
      default: 0,
    },
    itemHeight: {
      type: Number,
      default: 100,
    }
  },
  watch: {
    tableData: {
      handler(v) {
        this.processingData();
      },
    },
    'vehicleData.isCommercialVehicles': {
      handler(v) {
        // if (v) {
          this.statuTypes = {
            0: { title: "停工", color: "#FCB818", showBar: true },
            1: { title: "开工", color: "#3470FF", showBar: true },
          }
        // }else {
        //   this.statuTypes = {
        //     0: { title: "怠速", color: "#FCB818", showBar: true },
        //     2: { title: "作业", color: "#3470FF", showBar: true },
        //   }
        // }
      },
      immediate: true,
      deep: true,
    }
  },
  data() {
    return {
      chartsOption: {},
      statuTypes: {
        4: { title: "停工", color: "#FCB818", showBar: true },
        2: { title: "开工", color: "#3470FF", showBar: true },
      },
      tableConditions: {
        pageIndex: 1,
        pageSize: 10
      }
    };
  },
  methods: {
    processingData() {
        let arrList = [];
        for (const key in this.tableData) {
          arrList.push({
            name: key,
            value: this.tableData[key].reverse()
          });
        }
        this.$nextTick(()=>{
          this.gantt(arrList);
        })
    },
    gantt(chartData) {
      d3.select("#gantt").selectChildren("svg").remove();
      const nowDate = dayjs().format("YYYY-MM-DD");
      let data = chartData;
      let myGroups = Array.from(
        new Set(data.map((d) => `${d.name}`))
      );
      let devData = [];
      data.forEach((item) => {
        if (item.value && item.value.length) {
          item.value.forEach((t) => {
            if (this.statuTypes[t.status].showBar) {
              t.beginDateStr = dayjs(t.beginDate).format('HH:mm:ss');
              t.endDateStr = dayjs(t.endDateTime).format('HH:mm:ss');
              devData.push({
                ...t,
                machineNo: `${item.name}`,
              });
            }
          });
        }
      });
      data = devData;
      let itemHeight = this.itemHeight;
      let totalHeight = myGroups.length * itemHeight;
      const margin = {
          top: 10,
          right: 30,
          bottom: 30,
          left: d3.max(
            myGroups.map((item) => {
              return item.length * 8 || 0;
            }) || 30
          ),
        },
        width = 1800 - margin.left - margin.right,
        height = totalHeight - margin.top - margin.bottom;
      const svg = d3
        .select("#gantt")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);

      const x = d3.scaleTime(
        [
          dayjs(`${nowDate} 00:00:00`),
          dayjs(`${nowDate} 00:00:00`).add(1, "day"),
        ],
        [0, width]
      );
      const y = d3
        .scaleBand()
        .domain(myGroups)
        .range([0, height])
        .paddingOuter(0.5)
        .paddingInner(1);
      const axisX = d3.axisTop(x).ticks(24, "%H:%M").tickSize(-height);
      svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0, ${margin.top})`)
        .call(axisX)
        .select(".domain")
        .remove();
      svg
        .selectAll(".tick line")
        .attr("stroke", "rgba(0,0,0,0.1)")
        .style("stroke-dasharray", "2,2");
      svg
        .append("g")
        .attr("transform", `translate(0, ${margin.top})`)
        .call(d3.axisLeft(y))
        .select(".domain")
        .remove();
      svg
        .append("g")
        .selectAll("rect")
        .data(data)
        .enter()
        .append("rect")
        .attr("fill", (d) => {
          return this.statuTypes[d.status || 0].color || "#cccccc";
        })
        .attr("x", (d) => {
          return x(dayjs(`${nowDate} ${d.beginDateStr}`));
        })
        .attr("y", (d) => {
          return y(d.machineNo);
        })
        .transition()
        .duration(1000)
        .attr("width", (d) => {
          return (
            x(dayjs(`${nowDate} ${d.endDateStr}`)) -
            x(dayjs(`${nowDate} ${d.beginDateStr}`))
          );
        })
        .attr("height", 20);
    },
    ganttChange(i) {
      this.statuTypes[i].showBar = !this.statuTypes[i].showBar;
      this.processingData();
    },
    handleSizeChange(e) {
      this.tableConditions.pageSize = e;
      if (this.tableConditions.pageIndex * this.tableConditions.pageSize > this.total) {
        this.tableConditions.pageIndex = Math.ceil(this.total / e);
      };
      this.$emit('changeConditions', this.tableConditions);
    },
    handleCurrentChange(e) {
      this.tableConditions.pageIndex = e;
      this.$emit('changeConditions', this.tableConditions);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .gantt {
    flex: 1;
    overflow: auto;
  }
}
</style>