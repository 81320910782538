<template>
  <el-dialog
    :title="`${editDetail.id ? '编辑跟车' : '添加跟车'}`"
    :visible.sync="dialogVisible"
    custom-class="everyday-details-dialog"
    :width="'636px'"
    :before-close="handleClose"
  >
    <div class="details-box" v-loading="mapLoading">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="车辆状况" prop="vehicleCondition">
          <el-radio-group v-model="ruleForm.vehicleCondition">
            <el-radio label="1">良好</el-radio>
            <el-radio label="2">一般</el-radio>
            <el-radio label="3">维修</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="驾驶行为习惯" prop="drivingHabits">
          <el-radio-group v-model="ruleForm.drivingHabits">
            <el-radio label="1">良好</el-radio>
            <el-radio label="2">一般</el-radio>
            <el-radio label="3">差</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="行驶路线" prop="drivingRoute">
          <el-input v-model="ruleForm.drivingRoute"></el-input>
        </el-form-item>
        <el-form-item label="跟车人" prop="patcher">
          <el-input v-model="ruleForm.patcher"></el-input>
        </el-form-item>
        <el-form-item label="跟车日期" prop="patcherDate">
          <el-date-picker
            type="date"
            placeholder="选择日期" value-format="yyyy-MM-dd"
            v-model="ruleForm.patcherDate"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="跟车报告" prop="patcherReportUrl">
          <el-upload
            action="#"
            multiple
            :show-file-list="false"
            accept=".pdf,.doc,.docx"
            :http-request="() => {}"
            :on-change="uploadChange"
          >
            <el-button size="mini" type="primary" class="up-btn" v-if="!fileObject.name && !ruleForm.patcherReportUrl">
              <div class="up-btn-text">
                <i class="el-icon-upload"></i>
                <span>上传文件</span>
              </div>
            </el-button>
            <el-button size="mini" type="text" v-else>
              {{ fileObject.name || '已上传' }}
            </el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="ruleForm.remark"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">关闭</el-button>
      <el-button type="primary" @click="handleSubmit"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {
  followingAddOrUpdate
} from "@/api/riskData";
import { OSSDirectPass } from "@/api/oss.js";
export default {
  components: {},
  props: {
    showDialog: Boolean,
    editDetail: {
      type: Object,
      default: () => {}
    }
  },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        } else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;  
          this.ruleForm = {
            vehicleCondition: "1",
            drivingHabits: "1",
            drivingRoute: "",
            patcher: "",
            patcherDate: "",
            patcherReportUrl: "",
            remark: "",
            ...this.editDetail
          };
          this.fileObject = {};
          // Object.assign(this.ruleForm, this.editDetail)
        } else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        vehicleCondition: "1",
        drivingHabits: "1",
        drivingRoute: "",
        patcher: "",
        patcherDate: "",
        patcherReportUrl: "",
        remark: "",
      },
      rules: {
        patcher: [
          { required: true, message: "请输入跟车人", trigger: "change" },
        ],
        patcherDate: [
          { required: true, message: "请选择跟车日期", trigger: "change" },
        ],
      },
      mapLoading: false,
      fileObject: {}
    };
  },
  methods: {
    async init() {
      try {
      } catch (error) {
        console.log(error);
      }
    },
    handleClose(done) {
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      done();
      //   })
      //   .catch((_) => {});
    },
    handleSubmit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          if (this.fileObject && this.fileObject.name) {
            const url = await OSSDirectPass(this.fileObject);
            this.ruleForm.patcherReportUrl = url;
          }
          let res = await followingAddOrUpdate(this.ruleForm);
          if (res.code == 200) {
            this.dialogVisible = false;
            this.$emit('changeList')
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleReset() {},
    async uploadChange(file) {
      try {
        let fileSize = file.size / 1024 / 1024;
        if (fileSize > 10) {
          this.$message({
            message: `上传的文件大于${10}MB`,
            type: "warning",
          });
          return;
        };
        this.fileObject = file;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.everyday-details-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    // background: #4278c9;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
  .details-box {
    padding: 24px;
    &-title {
      display: flex;
      margin-bottom: 12px;
      padding-left: 24px;
      img {
        width: 96px;
        height: 96px;
        margin-right: 18px;
        margin-top: 0;
        border-radius: 4px;
      }
      &-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        &-status {
          height: 24px;
          line-height: 24px;
          font-size: 12px;
          color: #999999;
          padding: 0 8px;
          background: #eeeeee;
          border-radius: 2px;
          border: 1px solid #e1e1e1;
          width: max-content;
        }
        &-status1 {
          color: #00bc0d;
          background: #e5f8e6;
          border: 1px solid #b7edba;
        }
        &-zx {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;
          font-size: 16px;
          color: #333333;
          &-status {
            height: 28px;
            line-height: 28px;
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            padding: 0 16px;
            background: #eeeeee;
            border-radius: 14px 0px 0px 14px;
            border: 1px solid #e1e1e1;
          }
          &-status2 {
            color: #00bc0d;
            background: #e5f8e6;
            border: 1px solid #b7edba;
          }
          &-status1 {
            color: #00bc0d;
            background: #e5f8e6;
            border: 1px solid #b7edba;
          }
          &-status0 {
            color: #fca118;
            background: #fff5e7;
            border: 1px solid #ffe4bd;
          }
          &-status3 {
            color: #3470ff;
            background: #eaf0ff;
            border: 1px solid #c5d6ff;
          }
        }
        &-type {
          font-size: 16px;
          color: #333333;
        }
      }
    }
  }
}
.dialog-footer {
  .cl-button {
    background: #0080ff;
    border: 1px solid #0080ff;
  }
  .is-disabled {
    opacity: 0.5;
  }
}
</style>
