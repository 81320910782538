<template>
  <div class="wwww">
    <el-tabs type="border-card" v-model="editableTabsValue">
      <el-tab-pane label="报警数据" name="alarm"> </el-tab-pane>
      <el-tab-pane label="设备故障" name="fault"></el-tab-pane>
    </el-tabs>
    <div class="hour" v-show="editableTabsValue == 'alarm'">
      <div class="hour-head">
        <div class="hour-head-item">
          <div class="hour-head-refresh" @click="getList">
            <img
              src="../../../assets/images/realTimePositioning/map-sx.png"
              alt=""
              srcset=""
            />
            <span>刷新</span>
          </div>
        </div>
        <div class="hour-head-item">
          <div class="hour-head-twins" style="margin-left: 8px">
            <!-- <div class="hour-head-twins-identical hour-head-twins-left">
              告警级别
              <el-select v-model="bodyData.level" placeholder="请选择">
                <el-option
                  v-for="item in levelOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <div class="hour-head-twins-identical hour-head-twins-right">
              报警类型
              <el-select
                v-model="bodyData.alarmTypeIdList"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="hour-head-twins-identical hour-head-twins-right"
              style="margin-right: 24px"
            >
              报警时间
              <div class="el-date-b">
                <div class="hour-head-twins-botton" @click="handleNextDate(-1)">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <el-date-picker
                  v-model="bodyData.dateTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
                <!--  -->
                <div
                  class="hour-head-twins-botton"
                  :class="{
                    'hour-head-twins-botton-d':
                      bodyData.dateTime == endDateTime,
                  }"
                  @click="handleNextDate(1)"
                >
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="hour-head-refresh"
            style="margin-left: 8px"
            @click="hadnleExport"
          >
            <img
              src="../../../assets/images/realTimePositioning/hour-dc.png"
              alt=""
              srcset=""
            />
            <span>导出</span>
          </div>
        </div>
      </div>
      <div class="hour-content" v-loading="isLoading">
        <SCTable
          ref="SCTable"
          :tableData="tableData"
          :total="tableTotal"
          @changeConditions="onChangeConditions"
        >
          <el-table-column type="selection" width="48"> </el-table-column>
          <el-table-column prop="index" label="序号" width="70">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="alarmTypeName" label="告警名称" width="180">
          </el-table-column>
          <!-- <el-table-column prop="level" label="告警级别" width="180">
            <template slot-scope="scope">
              <div
                class="hour-content-status"
                :class="['hour-content-status' + scope.row.level]"
              >
                {{ getStateLabel(scope.row.level) }}
              </div>
            </template>
          </el-table-column> -->
          <el-table-column prop="timeBegin" label="告警时间" width="180">
          </el-table-column>
          <el-table-column prop="alarmAddress" label="告警位置">
            <template slot-scope="scope">
              <AddressText
                :autoScroll="false"
                v-if="scope.row.lon && scope.row.lat"
                :lng="scope.row.lon"
                :lat="scope.row.lat"
              />
              <span v-else>--</span>
            </template>
          </el-table-column>
          <el-table-column prop="speed" label="车身速度(km/h)">
            <template slot-scope="scope"> {{ scope.row.speed }}km/h </template>
          </el-table-column>
          <el-table-column prop="date" label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="text" @click="handleAlarmDetails(scope.row)"
                >告警详情</el-button
              >
              <el-button
                type="text"
                @click="onHandleClick(scope.row)"
                v-if="
                  !currentVehicle.isCommercialVehicles &&
                  scope.row.handleState != 1 &&
                  handleHasPerms('M09SM04-handle')
                "
                >处理</el-button
              >
            </template>
          </el-table-column>
        </SCTable>
      </div>
    </div>
    <div class="hour" v-show="editableTabsValue == 'fault'">
      <div class="hour-head">
        <div class="hour-head-item">
          <div class="hour-head-refresh" @click="getList">
            <img
              src="../../../assets/images/realTimePositioning/map-sx.png"
              alt=""
              srcset=""
            />
            <span>刷新</span>
          </div>
        </div>
        <div class="hour-head-item">
          <div class="hour-head-twins" style="margin-left: 8px">
            <!-- <div class="hour-head-twins-identical hour-head-twins-left">
            告警级别
            <el-select v-model="bodyData.level" placeholder="请选择">
              <el-option
                v-for="item in levelOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div> -->
            <div class="hour-head-twins-identical hour-head-twins-right">
              故障类型
              <el-select v-model="faultData.status" placeholder="请选择">
                <el-option
                  v-for="item in faultOptions"
                  :key="item.alarmId"
                  :label="item.alarmName"
                  :value="item.alarmId"
                >
                </el-option>
              </el-select>
            </div>
            <div
              class="hour-head-twins-identical hour-head-twins-right"
              style="margin-right: 24px"
            >
              上报时间
              <div class="el-date-b">
                <div class="hour-head-twins-botton" @click="handleFaultNextDate(-1)">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <el-date-picker
                  v-model="faultData.dateTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
                <!--  -->
                <div
                  class="hour-head-twins-botton"
                  :class="{
                    'hour-head-twins-botton-d':
                      faultData.dateTime == endDateTime,
                  }"
                  @click="handleFaultNextDate(1)"
                >
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
          <div
            class="hour-head-refresh"
            style="margin-left: 8px"
            @click="hadnleFaultExport"
          >
            <img
              src="../../../assets/images/realTimePositioning/hour-dc.png"
              alt=""
              srcset=""
            />
            <span>导出</span>
          </div>
        </div>
      </div>
      <div class="hour-content" v-loading="isLoading">
        <SCTable
          ref="SCTable"
          :tableData="faultTableData"
          :total="faultTableTotal"
          @changeConditions="onChangeFaultConditions"
        >
          <el-table-column type="selection" width="48"> </el-table-column>
          <el-table-column prop="index" label="序号" width="70">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="devTime" label="上报时间" width="180">
          </el-table-column>
          <el-table-column prop="faultType" label="故障类型" width="180">
          </el-table-column>
          <el-table-column prop="remark" label="故障内容">
          </el-table-column>
        </SCTable>
      </div>
    </div>
    <alarmDetailsDialog v-model="isVisible" :currentVehicle="currentVehicle" :editDetail="editData" />

    <details-dialog
      v-model="isImport"
      :details="editData"
      @onHandleClick="onHandleClick(editData)"
      @downloadClick="handleDownloadSttachment(editData)"
    />

    <Dialog
      @on-cmdSend="onCmdSendChange"
      :visible="visibleDialog"
      :iProp="alarmiProp"
      @input="closeDialog"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import SCTable from "./table";
import alarmDetailsDialog from "@/views/RealTimePositioning/components/alarmDetailsDialog";
import DetailsDialog from "@/views/SecurityMonitoring/StatisticalReport/components/DetailsDialog.vue";
import Dialog from "@/views/SecurityMonitoring/StatisticalReport/components/DealWithDialog.vue";
import {
  SCAlramList,
  exportSCAlramList,
  getAlarmConf,
  getPCZKAlarmDetail,
} from "@/api/vehicleApi";
import {
  getDeviceAlarmConf,
  historyFault,
  historyFaultExport,
} from "@/api/SystemData";
import { hasPerms, dataPermissions } from "@/utils/auth";
import { appConfig } from "@/config/appConfig";
import AddressText from "@/components/AddressText";
export default {
  model: {
    prop: "currentVehicle",
  },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      bodyData: {
        dateTime: dayjs().format("YYYY-MM-DD"),
        level: "",
        alarmTypeIdList: "",
      },
      faultData: {
        dateTime: dayjs().format("YYYY-MM-DD"),
        status: "",
      },
      levelOptions: [
        {
          label: "全部",
          value: "",
        },
        {
          label: "低",
          value: "1",
        },
        {
          label: "中",
          value: "2",
        },
        {
          label: "高",
          value: "3",
        },
      ],
      typeOptions: [],
      endDateTime: dayjs().format("YYYY-MM-DD"),
      tableData: [],
      tatolTime: "0时0分",
      conditionsData: {
        pageIndex: 1,
        pageSize: 10,
      },
      tableTotal: 0,
      faultConditionsData: {
        pageIndex: 1,
        pageSize: 10,
      },
      faultTableData: [],
      faultTableTotal: 0,
      chartsData: {},
      editData: {},
      isVisible: false,
      isImport: false,
      visibleDialog: false,
      alarmiProp: {
        selects: [], // 多选报警
        alarmParmas: {}, // 单个报警数据
        type: 0, //报警类型 0:常规 1：adas报警
      },
      isLoading: false,
      editableTabsValue: "alarm",
      faultOptions: [], //
    };
  },
  components: {
    SCTable,
    alarmDetailsDialog,
    DetailsDialog,
    AddressText,
    Dialog,
  },
  watch: {
    bodyData: {
      handler(v) {
        this.getList("reset");
      },
      deep: true,
    },
    faultData: {
      handler(v) {
        this.getList("reset");
      },
      deep: true,
    },
    editableTabsValue: {
      handler(v) {
        this.getList();
      },
      deep: true,
    },
    currentVehicle: {
      handler(v, old) {
        // if (
        //   old &&
        //   old.isCommercialVehicles !== undefined &&
        //   old.isCommercialVehicles === v.isCommercialVehicles
        // )
        //   return false;
        getAlarmConf({
          manufactorType: v.manufactorType,
        }).then((res) => {
          if (res.obj && res.obj.length) {
            this.typeOptions = res.obj.map((val) => {
              return {
                label: val.alarmName,
                value: val.alarmId,
              };
            });
            this.typeOptions.unshift({
              label: "全部",
              value: "",
            });
          } else {
            this.typeOptions = [];
          }
        });
        getDeviceAlarmConf({
          manufactorType: v.manufactorType,
          // flag: v.isCommercialVehicles ? 1 : "",
        }).then((res) => {
          console.log(res);
          this.faultOptions = res.data;
          this.faultOptions.unshift({
            alarmName: "全部",
            alarmId: "",
          });
        });
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(type) {
      try {
        if (type == "reset") {
          this.$refs.SCTable.handleCurrentChange(1);
          return false;
        }
        if (!this.currentVehicle || !this.currentVehicle.vehicleId) {
          return false;
        }
        if (this.editableTabsValue == "alarm") {
          this.getAlarmList();
        } else {
          this.getFaultList();
        }
      } catch (error) {}
    },
    async getAlarmList() {
      try {
        let api = SCAlramList;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          ...this.conditionsData,
          ...this.bodyData,
        };
        if (this.bodyData.dateTime) {
          data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format(
            "YYYY-MM-DD 00:00:00"
          );
          data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format(
            "YYYY-MM-DD 23:59:59"
          );
        }
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.flag == 1) {
          this.tableData = res.obj.SCAlarm;
          this.tableTotal = res.obj.total || 0;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    },
    async getFaultList() {
      try {
        let api = historyFault;
        let data = {
          vehicleId: this.currentVehicle.vehicleId,
          manufactorType: this.currentVehicle.manufactorType,
          ...this.faultConditionsData,
          faultIdList: this.faultData.status ? [this.faultData.status] : [],
        };
        if (this.faultData.dateTime && this.faultData.dateTime.length) {
          data.beginDateTime = dayjs(this.faultData.dateTime).format(
            "YYYY-MM-DD 00:00:00"
          );
          data.endDateTime = dayjs(this.faultData.dateTime).format(
            "YYYY-MM-DD 23:59:59"
          );
        }
        this.isLoading = true;
        let res = await api(data);
        this.isLoading = false;
        if (res.code == 200) {
          this.faultTableData = res.data.faultPageList || [];
          this.faultTableTotal = res.data.total || 0;
        }
      } catch (error) {}
    },
    onChangeConditions(e) {
      this.conditionsData = e;
      this.getList();
    },
    onChangeFaultConditions(e) {
      this.faultConditionsData = e;
      this.getList();
    },
    hadnleExport() {
      let data = {
        vehicleId: this.currentVehicle.vehicleId,
        ...this.conditionsData,
        ...this.bodyData,
      };
      if (this.bodyData.dateTime) {
        data.alarmTimeBegin = dayjs(this.bodyData.dateTime).format(
          "YYYY-MM-DD 00:00:00"
        );
        data.alarmTimeEnd = dayjs(this.bodyData.dateTime).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      exportSCAlramList(data);
    },
    hadnleFaultExport() {
      let data = {
        vehicleId: this.currentVehicle.vehicleId,
        ...this.faultConditionsData,
        faultIdList: this.faultData.status ? [this.faultData.status] : [],
      };
      if (this.faultData.dateTime && this.faultData.dateTime.length) {
        data.beginDateTime = dayjs(this.faultData.dateTime).format(
          "YYYY-MM-DD 00:00:00"
        );
        data.endDateTime = dayjs(this.faultData.dateTime).format(
          "YYYY-MM-DD 23:59:59"
        );
      }
      historyFaultExport(data);
    },
    getStateLabel(e) {
      if (e === 1) return "低";
      if (e === 2) return "中";
      if (e === 3) return "高";
      return "未知";
    },
    handleNextDate(e) {
      if (e > 0 && this.bodyData.dateTime == this.endDateTime) return false;
      this.bodyData.dateTime = dayjs(this.bodyData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    handleFaultNextDate(e) {
      if (e > 0 && this.faultData.dateTime == this.endDateTime) return false;
      this.faultData.dateTime = dayjs(this.faultData.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
    },
    async handleAlarmDetails(e) {
      if (this.currentVehicle.isCommercialVehicles) {
        this.editData = e;
        this.isVisible = true;
      } else {
        try {
          let res = await getPCZKAlarmDetail({
            flag: "",
            alarmId: e.id,
            day: (e.timeBegin || e.devTime).split(" ")[0],
          });
          this.editData = Object.assign(e, res.obj);
          this.isImport = true;
        } catch (error) {}
      }
    },

    onHandleClick(row) {
      this.alarmiProp.selects = [];
      this.visibleDialog = true;
      this.alarmiProp.alarmParmas = {
        plate: row.plate, // 设备名称
        vehicleId: row.vehicleId, // 车辆Id
        groupName: row.groupName, // 车组名称
        groupId: row.groupId, // 车组Id
        alarmTime: row.timeBegin, // 报警时间
        alarmTypeEn: row.alarmTypeEn,
        alarmType: row.alarmType, // 报警类型
        alarmNameEnglish: row.alarmNameEnglish, // 报警类型
        alarmTypeCh: row.type,
        alarmAddress: row.address, // 报警地址
        alarmSourceStr: {
          1: "车载终端",
          2: "企业监控平台",
          3: "政府监管平台",
          9: "其他",
        }[row.alarmSource],
        alarmSource: row.alarmSource, // 报警来源
        alarmId: row.alarmId,
      };
    },
    handleDownloadSttachment(item) {
      // safetyManageDam({alarmId: item.alarmId})
      window.open(
        appConfig.base2GUrl2 +
          `/safetyManage/dam?alarmId=${item.alarmId}&token=${this.$store.state.appToken}&platform=PC`,
        "_blank"
      );
    },
    // 模态框关闭
    closeDialog(model) {
      this.visibleDialog = model;
    },
    // 指令下发后刷新数据
    onCmdSendChange() {
      this.isImport = false;
      this.getList();
    },
    handleHasPerms(e) {
      return hasPerms(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.wwww {
  height: 100%;
  ::v-deep .el-tabs {
    border: none !important;
    box-shadow: none;
    background-color: transparent !important;
    .el-tabs__header {
      border: none !important;
      background-color: transparent !important;
    }
    .el-tabs__content {
      display: none !important;
    }
    .el-tabs__item {
      color: #333333;
      border: none !important;
    }
    .is-active {
      color: #0080ff;
      font-weight: bold;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
.hour {
  padding: 16px 12px 16px;
  background: #ffffff;
  height: calc(100% - 40px);
  border-radius: 0px 0px 10px 10px;
  &-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item {
      display: flex;
      align-items: center;
    }
    &-refresh {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 54px;
      height: 32px;
      font-weight: 400;
      font-size: 12px;
      color: #333333;
      background: #f5f5f5;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #dee3e6;
      img {
        width: 14px;
        height: 14px;
        margin-right: 2px;
        margin-top: -3px;
      }
    }
    &-record {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      font-weight: bold;
      font-size: 14px;
      color: #0080ff;
      background: #e6f3ff;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 32px;
        height: 32px;
      }
      div {
        padding: 0 10px;
      }
    }
    &-twins {
      display: flex;
      align-items: center;
      overflow: hidden;
      &-identical {
        display: flex;
        align-items: center;
        margin-right: 50px;
        ::v-deep .el-select {
          margin-left: 8px;
        }
        ::v-deep .el-date-editor {
          width: 169px;
          margin: 0 8px;
        }
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
      }
      &-botton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        background: #f2f7fd;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #dee3e6;
        &-d {
          cursor: no-drop;
          opacity: 0.8;
        }
      }
      // &-left {
      //   border: 1px solid #DEE3E6;
      //   border-radius: 16px 0 0 16px;
      //   border-right: none;
      // }
      // &-right {
      //   border: 1px solid #DEE3E6;
      //   border-left: none;
      //   border-radius: 0 16px 16px 0;
      // }

      // &-check-list {
      //   border-color: #0080FF;
      //   border-right: 1px solid #0080FF;
      //   color: #0080FF;
      // }
      // &-check-charts {
      //   border-color: #0080FF;
      //   border-left: 1px solid #0080FF;
      //   color: #0080FF;
      // }
    }
  }
  &-content {
    height: calc(100% - 56px);
    margin-top: 24px;
    &-status {
      width: 43px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      border-radius: 20px;
    }
    &-status1 {
      background: #409eff;
    }
    &-status2 {
      background: #fda835;
    }
    &-status3 {
      background: #f64a2d;
    }
  }
}
</style>