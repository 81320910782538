<template>
  <div class="risk">
    <div class="risk-model">
      <div class="risk-model-title">
        <div class="risk-model-title-name">风控统计</div>
        <div class="risk-model-title-right">
              <div class="el-date-b">
                <div class="risk-model-title-right-botton" @click="handleNextDate(-1)">
                  <i class="el-icon-arrow-left"></i>
                </div>
                <el-date-picker style="margin: 0 8px;"
                  v-model="dateTime"
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                >
                </el-date-picker>
                <!--  -->
                <div
                  class="risk-model-title-right-botton"
                  :class="{
                    'risk-model-title-right-botton-d':
                      dateTime == endDateTime,
                  }"
                  @click="handleNextDate(1)"
                >
                  <i class="el-icon-arrow-right"></i>
                </div>
              </div>
        </div>
      </div>
      <div class="risk-model-content">
        <div class="risk-model-content-item">
          <div class="risk-model-content-item-left">
            <div class="risk-model-content-item-left-top">当天天气</div>
            <div class="risk-model-content-item-left-down">
              <div class="risk-model-content-item-left-down-v">{{ riskObjects.weather || '--' }}</div>
            </div>
          </div>
          <div class="risk-model-content-item-right">
            <img :src="getWeatherIcon(riskObjects.weather)" alt="">
          </div>
        </div>
        <div class="risk-model-content-item">
          <div class="risk-model-content-item-left">
            <div class="risk-model-content-item-left-top">是否开工</div>
            <div class="risk-model-content-item-left-down">
              <div class="risk-model-content-item-left-down-v">{{ riskObjects.isWork ? '开工' : '未开工' }}</div>
            </div>
          </div>
          <div class="risk-model-content-item-right">
            <img src="../../../assets/images/realTimePositioning/risk-kgkg.png" alt="">
          </div>
        </div>
        <div class="risk-model-content-item">
          <div class="risk-model-content-item-left">
            <div class="risk-model-content-item-left-top">开工总时长(h)</div>
            <div class="risk-model-content-item-left-down">
              <div class="risk-model-content-item-left-down-v">{{ riskObjects.sumWorkTime || '--' }}</div>
              <div class="risk-model-content-item-left-down-t">
                <div class="risk-model-content-item-left-down-t-i" style="margin-bottom:4px">
                  中午工时<span>{{ riskObjects.noonWorkTime || '--' }}h</span>
                </div>
                <div class="risk-model-content-item-left-down-t-i">
                  晚上工时<span>{{ riskObjects.nightWorkTime || '--' }}h</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="risk-model-content-item">
          <div class="risk-model-content-item-left">
            <div class="risk-model-content-item-left-top">平均行驶速度(km/h)</div>
            <div class="risk-model-content-item-left-down">
              <div class="risk-model-content-item-left-down-v">{{ riskObjects.averageSpeed || '--' }}</div>
              <div class="risk-model-content-item-left-down-t">
                <div class="risk-model-content-item-left-down-t-i">
                  最高<span>{{ riskObjects.maxSpeed || '--' }}km/h</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="risk-model-content-item" v-for="item,index in riskObjects.yoy || []" :key="index">
          <div class="risk-model-content-item-left">
            <div class="risk-model-content-item-left-top">{{ item.alarmTypeName }}</div>
            <div class="risk-model-content-item-left-down">
              <div class="risk-model-content-item-left-down-v">{{ item.count }}</div>
              <div class="risk-model-content-item-left-down-t">
                <div class="risk-model-content-item-left-down-t-i">
                  同比<span :style="{ color: item.yoy >= 0 ? '#F64A2D' : '#00BC0D' }">
                    {{ item.yoy >= 0 ? '+' : '-'}}
                    {{ item.yoy }}
                    <i v-if="item.yoy >= 0" class="el-icon-caret-top"></i>
                    <i v-else class="el-icon-caret-bottom"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="risk-model" style="flex: 1;display: flex;
  flex-direction: column;">
      <div class="risk-model-title">
        <div class="risk-model-title-name">近七天告警趋势</div>
        <div class="risk-model-title-right">
        </div>
      </div>
      <div class="risk-model-content" style="flex: 1;">
        <Chart style="height:calc(100% - 16px);min-height: 100px"
      ref="chartBox"
      :option="lineOptions"></Chart>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { getRiskByVehicle } from "@/api/vehicleApi";
import { getWeatherIcon } from "@/utils/weatherTool.js";
import Chart from "@/components/Chart/Charts";
export default {
  model: {
    prop: "currentVehicle",
  },
  components: { Chart },
  props: {
    currentVehicle: {
      type: Object,
      default: null,
    },
  },
  watch: {
    currentVehicle: {
      handler(val) {
        this.init();
      },
      deep: true,
      immediate: true
    },
  },
  data() {
    return {
      dateTime: dayjs().format("YYYY-MM-DD"),
      endDateTime: dayjs().format("YYYY-MM-DD"),
      riskObjects: {},
      getWeatherIcon,
      lineOptions: {},
    }
  },
  methods: {
    async init() {
      try {
        let res = await getRiskByVehicle({
          vehicleId: this.currentVehicle.vehicleId,
          beginDate: this.dateTime,
          manufactorType: this.currentVehicle.manufactorType,
        });
        if (res.code == 200) {
          this.riskObjects = res.data;
          this.initChats(res.data.sevenMap);
        }else {
          this.riskObjects = {};
        }
      } catch (error) {
        
      }
    },
    handleNextDate(e) {
      if (e > 0 && this.dateTime == this.endDateTime) return false;
      this.dateTime = dayjs(this.dateTime)
        .add(e, "day")
        .format("YYYY-MM-DD");
      this.init();
    },
    async initChats(dataList) {
      // 颜色
      const colorList = [
        "rgba(41, 91, 255, 1)",
        "rgba(97, 216, 235, 1)",
        "rgba(167, 123, 243, 1)",
        "rgba(251, 194, 38, 1)",
        "rgba(248, 248, 82, 1)",
        "rgba(84, 223, 139, 1)",
        "rgba(120, 174, 255, 1)",
        "rgba(152, 102, 222, 1)",
        "rgba(204, 121, 232, 1)",
        "rgba(255, 178, 89, 1)",
        "rgba(0, 186, 173, 1)",
        "rgba(172, 51, 193, 1)",
      ];
      let i = 0;
      let xAxisData = [];
      let seriesList = [];
      let legendData = [];
      for (const key in dataList) {
        xAxisData.push(key);
        dataList[key].map(val => {
          if (legendData.includes(val.alarmTypeName)) {
            seriesList[seriesList.findIndex(val2=> val2.name == val.alarmTypeName)].data.push(val.count);
          }else {
            legendData.push(val.alarmTypeName);
            seriesList.push({
              name: val.alarmTypeName,
              "data": [val.count],
              "type": "line",
              "smooth": true,
              "symbol": "none",
              "color": {
                "x": 0,
                "y": 0,
                "x2": 0,
                "y2": 1,
                "type": "linear",
                "global": false,
                "colorStops": [
                  {
                    "offset": 0,
                    "color": colorList[seriesList.length]
                  }
                ]
              }
            });
          }
        })
        i++;
      };
      await this.$nextTick();
      // 数图配置项
      this.lineOptions = {
        tooltip: {
          trigger: "axis",
        },
        title: {
          text: '告警次数',
          textStyle: {
            color: '#999',
            fontSize: 12,
          },
          top: '1%',
        },
        grid: {
          top: "16%", // 等价于 y: '16%'
          left: "3%",
          right: "8%",
          bottom: "30",
          containLabel: true
        },
        legend: {
          icon: 'circle',
          show: true,
          itemWidth: 8,
          itemHeight: 8,
          data: legendData,
          textStyle: {
            //图例文字的样式
            color: "#333"
          },
          left: "center",
          bottom: 0
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          show: true,
          data: xAxisData,
          axisLine: {
            lineStyle: {
              color: "#ccc"
            }
          },
          axisTick: {
            inside: true,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#333"
            }
          }
        },
        yAxis: {
          type: "value",
          axisLine: {
            lineStyle: {
              color: "#ccc"
            }
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "rgba(13, 151, 235, .3)"
            }
          },
          axisLabel: {
            formatter: function (value) {
              return value;
            },
            textStyle: {
              color: "#333"
            }
          }
        },
        series: seriesList
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.risk {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 30px;
  background: #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  &-model {
    &-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 58px;
      &-name {
        position: relative;
        padding-left: 14px;
        font-weight: bold;
        font-size: 16px;
        color: #333333;
      }
      &-name::before {
        content: '';
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 18px;
        background: #4278C9;
      }
      &-right {
        .el-date-b {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }
        &-botton {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          cursor: pointer;
          background: #f2f7fd;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #dee3e6;
          &-d {
            cursor: no-drop;
            opacity: 0.8;
          }
        }
      }
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 98px;
        width: 220px;
        margin-bottom: 16px;
        margin-left: 16px;
          padding: 16px;
        background: #F2F7FD;
        border-radius: 6px;
        &-left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          &-top {
            font-size: 14px;
            color: #666666;
          }
          &-down {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 24px;
            color: #333333;
            &-v {

            }
            &-t {
              margin-left: 16px;
              &-i {
                font-size: 14px;
                color: #666666;
                font-weight: 400;
                span {
                  font-size: 14px;
                  color: #0080FF;
                  margin-left: 4px;
                }
              }
            }
          }
        }
        &-right {
          img {
            width: 32px;
            height: 32px;
          }
        }
      }
    }
  }
}
</style>